@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap");
.card_products {
  display: grid;
  grid-template-columns: auto auto auto auto; /* Ensure four columns layout by default */
  align-items: center;
  justify-content: center;
  margin-left: 150px;
  height: 100%;
  overflow: hidden;
}
.single__product {
  position: relative;
  background-image: linear-gradient(to top, #51cef8 5%, #6f86d6 100%);
  border-radius: 18px;
  cursor: pointer;
  animation: animate 1s ease-in;
  margin: 10px;
  width: 250px;
  height: 320px;
}

.single__product:hover {
  box-shadow: 5px 5px 10px -5px #14afc4;
}

.product__content {
  display: flex;
  flex-direction: column;
  gap: 10;
  align-items: center;
  padding: 0px 8px;
  padding-bottom: 15px;
}
.product__content h4 {
  color: #fff;
  font-weight: bold;
  font-size: 0.8rem;
}
.product__img img {
  width: 100%;
  height: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  object-fit: fill;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.product__content h6 {
  color: #e4e1d8;
  text-align: center;
  width: 70%;
}

.price {
  color: #fff;
}

.price span {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
}

.stroke {
  -webkit-text-stroke: 0.3px #e6692f;
}
.product__detail {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border: none;
  font-size: 0.8rem;
  padding: 5px 20px;
  background: transparent;
  color: #4291d1;
  border-radius: 16px;
  cursor: pointer;
  background: #fff;
  bottom: 5px;
}
@keyframes animate {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

/* Before Promotion */
.product-price {
  font-size: 16px; /* Adjust size as needed */
  color: #333; /* Default color */
}

/* After Promotion */
.promotion {
  font-weight: bold;
  font-size: 24px;
  color: #c2c4c7; /* Red color for promotion */
  text-decoration: line-through; /* Strikethrough for original price */
}

/* Discounted Price */
.product-price.discounted {
  color: #008000; /* Green color for discounted price */
}

@media only screen and (max-width: 1200px) {
  .card_products {
    grid-template-columns: auto auto;
    margin-left: 10px;
    margin: 0px auto;
  }
  .single__product {
    margin: 5px;
    max-width: 190px;
  }

  .product__content h6 {
    font-size: 0.9rem;
  }

  .price {
    font-size: 0.8rem;
  }

  .price span {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 420px) {
  .card_products {
    grid-template-columns: auto auto;
    margin: 0px 4px;
  }
  .single__product {
    margin: 5px;
    max-width: 180px;
  }
  .product__content h6 {
    font-size: 0.8rem;
  }
  .price {
    font-size: 0.7rem;
  }
  .price span {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 390px) {
  .card_products {
    grid-template-columns: auto auto;
    margin: 0px 5px;
  }
  .single__product {
    margin: 5px;
    max-width: 150px;
  }
  .product__content h6 {
    font-size: 0.8rem;
  }
  .price {
    font-size: 0.7rem;
  }
  .price span {
    font-size: 0.8rem;
  }
}
