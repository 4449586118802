.filter-btn {
  border: 3px solid #1a69cf81;
  padding: 7px 15px;
  background: transparent;
  margin-top: 5px;
  margin-left: 5px;
  color: #1f1a1a;
  border-radius: 18px;
  cursor: pointer;
}

.menu__title {
  color: #207aad;
  font-weight: bold;
  font-size: 1.75rem;
  margin: 20px 0px;
}

.active__btn {
  background: #138ec781;
  color: #fff;
  border: 3px solid #fff;
}

@media only screen and (max-width: 992px) {
  .menu__title {
    font-size: 1.4rem;
  }
  .filter-btn {
    padding: 5px 20px;
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 767px) {
  .filter-btn {
    margin-bottom: 15px;
  }
}
