@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --header-height: 2rem;
  --nav-width: 4.25rem;
  --first-color: #092a85;
  --gold: #fde047;

  --body-color: #f3f5fa;
  --grey-color: #637381;
  --purple-color: #8069f2;
  --light-color: #eeeeee;
  --green-color: #00ab55;
  --light-blue-color: #d6e5fa;
  --yellow-color: #fcd53b;
  --blue-color: #265df1;
  --red-color: #ef5b5b;
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100;
}

::-webkit-scrollbar {
  display: none;
}
*,
::before,
::after {
  box-sizing: border-box;
}

body {
  position: relative;
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  color: var(--grey-color);
  font-size: var(--normal-font-size);
  transition: 0.5s;
}
.button-cart-inc-dec {
  z-index: 9999 !important;
}
.product-card {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dressingBlink {
  0% {
    background-color: rgba(255, 0, 0, 0.2); /* Light red */
  }
  50% {
    background-color: rgba(255, 0, 0, 0.6); /* Darker red */
  }
  100% {
    background-color: rgba(255, 0, 0, 0.2); /* Light red */
  }
}

.slick-arrow.slick-next,
.slick-arrow.slick-prev {
  background-color: #428cff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0.8;
  height: 40px;
  width: 40px;
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover {
  background-color: #2574f5;
  opacity: 1;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.arrows {
  height: 30px;
  width: 30px;
  margin: 5px;
}

#root {
  max-width: 1400px;
  margin: 0 3px;
  padding: 0.25rem;
  text-align: center;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

@layer base {
  * {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
  }

  /* container */
  .nike-container {
    @apply w-11/12 m-auto;
  }

  /* Theme-Colors */
  .bg-theme {
    background-image: linear-gradient(
      0.2deg,
      rgba(40, 173, 222, 1) 4.8%,
      rgb(27, 76, 224) 85.5%
    );
  }
  .bg-theme-cart {
    @apply bg-gradient-to-b from-slate-900 to-gray-900 shadow-sm shadow-gray-900;
  }

  /* Clip-path */
  .clip-path {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 65%);
  }

  /* Blur-Effect */
  .blur-effect-theme {
    @apply bg-white/50 bg-clip-padding backdrop-filter backdrop-blur bg-opacity-50 opacity-100 z-[100] shadow-sm shadow-slate-100 transition-all duration-300;
  }

  /* Icon Stles */
  .icon-style {
    @apply h-6 w-6 sm:h-5 sm:w-5 text-slate-100 cursor-pointer transition-all duration-100 active:scale-110 leading-tight;
  }

  /* text-gradient */
  .text-gradient {
    @apply text-transparent bg-clip-text bg-gradient-to-tr from-blue-500 to-sky-500;
  }

  /* border */
  .rounded-theme {
    border-radius: 5% 100% 10% 5%;
  }

  /* button */
  .button-theme {
    @apply px-7 py-1 rounded active:scale-90 transition-all duration-100 ease-in-out shadow-md sm:text-sm;
  }

  /* tranistions-theme */
  .transitions-theme {
    @apply transition-all duration-700 ease-in-out cursor-pointer;
  }
}
.float {
  position: fixed;
  padding: 10px;
  width: 60px;
  height: 60px;
  bottom: 30%;
  right: 18%;
  background-color: #e09c1e;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  transition: all ease-in-out 0.7s;
  outline: none;
}
.float:hover {
  transform: scale(1.2) rotate(360deg);
  background-color: rgb(209, 15, 15);
}
.my-float {
  margin-bottom: 10px;
}
@layer base {
  button {
    @apply border border-black rounded-xl px-5 py-1;
  }
}

.react-rater-star.is-active-half:before {
  color: var(--gold) !important;
}

.react-rater-star.is-active {
  color: var(--gold) !important;
}

a {
  text-decoration: none;
}

.ptb-100 {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}
.pt-100 {
  padding-top: 6.25rem;
}
.pt-200 {
  padding-top: 12.5rem;
}
.pb-100 {
  padding-bottom: 6.25rem;
}
.pb-70 {
  padding-bottom: 4.4rem;
}

/* PAGES */
.page__main {
  padding-top: 2.25rem;
}
.page__main h1 {
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

select {
  padding: 12px;
  color: #000;
  font-weight: bold;
  border: 2px solid #000;
}
.selOptions {
  color: #383838;
  width: auto;
  min-width: 250px;
  margin-top: 15px;
}
.collabList {
  flex: 4;
  border-radius: 12px;
}

.collabListEdit {
  background-color: #3bb077;
  border-radius: 50%;
  padding: 5px;
  color: white;
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.collabListDelete {
  color: red;
  cursor: pointer;
  height: 30px;
  width: 30px;
}
.collabListEye {
  color: rgb(231, 139, 18);
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.collabListEdit:hover {
  background-color: white;
  color: #3bb077;
}

.collabListDelete:hover {
  background-color: red;
  color: white;
}
.collabListEye:hover {
  background-color: rgb(226, 95, 18);
  color: white;
}
.addnewCollab {
  background-color: #000;
  margin: 80px 50px;
  color: rgb(114, 112, 112);
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 240px;
  font-size: 15px;
  font-weight: 550;
  cursor: pointer;
  color: #d4cccc;
}

.addnewCollab:hover {
  background-color: rgb(196, 193, 193);
  color: rgb(99, 99, 122);
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;

  align-items: center;
  border: 2px dashed #1475cf;
  height: 150px;
  width: 250px;
  cursor: pointer;
  border-radius: 5px;
}
.forms {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  border: 6px dashed #40eb73a7;
  height: 250px;
  width: 350px;
  cursor: pointer;
  border-radius: 12px;
}

.uploaded-row {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #e9f0ff;
}

.upload-content {
  display: flex;
  align-items: center;
}
/* form video */
.form-video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;

  align-items: center;
  border: 2px dashed #43cc30;
  height: 150px;
  width: 250px;
  cursor: pointer;
  border-radius: 5px;
}
.video-upd {
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.uploaded-row-video {
  margin: 25px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #8ac279;
}

.upload-content-video {
  display: flex;
  align-items: center;
}

.containerer {
  color: #fff;
  display: flex;

  height: 70vh;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.loading-page {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #23a5dd, #0585af, #054d6b);
  display: flex;

  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  color: #191654;
  opacity: 1;
  transition: opacity 1.5s ease, display 1.5s ease;
}

#svg {
  height: 150px;
  width: 150px;
  stroke: white;
  fill-opacity: 0;
  stroke-width: 3px;
  stroke-dasharray: 4500;
  animation: draw 8s ease;
}

@keyframes draw {
  0% {
    stroke-dashoffset: 4500;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.name-container {
  height: 30px;
  overflow: hidden;
}

.logo-name {
  color: #fff;
  font-size: 20px;
  letter-spacing: 12px;
  text-transform: uppercase;
  margin-left: 20px;
  font-weight: bolder;
  opacity: 0; /* Initial opacity for the animation */

  transition: opacity 2s ease, transform 2s ease;
}
