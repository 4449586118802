.image-gallery {
  width: 92%;
  height: auto;
  margin-left: -21px;
  margin-right: 5px;
}

.image-gallery-slide img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}
.image-gallery-thumbnail {
  margin-top: 20px;
}
.image-gallery-thumbnail img {
  width: 100%;
  height: 70px;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}
.fullscreen .image-gallery-slide img {
  height: 300px;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.react-medium-image-zoom-overlay {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

video {
  width: 90%;
  height: 400px;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1200px) {
  .image-gallery {
    width: 80vw;
    height: auto;
    margin-left: -21px;
    margin-right: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .image-gallery {
    width: 91vw;
    height: auto;
    margin-left: -21px;
    margin-right: 5px;
  }
}
