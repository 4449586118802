@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap");
.card_products_comm {
  display: grid;
  grid-template-columns: auto auto auto auto; /* Ensure four columns layout by default */
  align-items: center;
  justify-content: center;
  margin-left: 150px;
  height: 100%;
  overflow: hidden;
}
.single__product_comm {
  position: relative;
  background-color: #91c8e4;
  border-radius: 18px;

  cursor: pointer;
  animation: animate 1s ease-in;
  margin: 10px;
  width: 230px;
  height: 280px;
}

.single__product_comm:hover {
  box-shadow: 5px 5px 10px -5px #14afc4;
}

.product__content_comm {
  display: flex;
  flex-direction: column;
  gap: 10;
  align-items: center;
  padding: 0px 8px;
  padding-bottom: 15px;
}
.product__content_comm h4 {
  color: #fff;
  font-weight: bold;
  font-size: 0.8rem;
}
.product__img_comm img {
  width: 100%;
  height: 150px;
  object-fit: fill;
  background-repeat: no-repeat;
  background-size: cover;
}
.product__content_comm h6 {
  color: #e4e1d8;
  text-align: center;
  width: 70%;
}

.price_comm {
  color: #fff;
}

.price_comm span {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
}

.product__detail_comm {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 0.8rem;
  padding: 5px 20px;
  background: transparent;
  color: #fdf9f9;
  border-radius: 16px;
  cursor: pointer;
  background: #0369ad;
  bottom: 5px;
}
@keyframes animate {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

/* Before Promotion */
.product-price_comm {
  font-size: 16px; /* Adjust size as needed */
  color: #333; /* Default color */
}

/* After Promotion */
.promotion_comm {
  font-weight: bold;
  font-size: 24px;
  color: #c2c4c7; /* Red color for promotion */
  text-decoration: line-through; /* Strikethrough for original price */
}

/* Discounted Price */
.product-price.discounted {
  color: #008000; /* Green color for discounted price */
}

@media only screen and (max-width: 1200px) {
  .card_products_comm {
    grid-template-columns: auto auto;
    margin-left: 10px;
    margin: 0px auto;
  }
  .single__product_comm {
    margin: 5px;
    max-width: 190px;
  }

  .product__content_comm h6 {
    font-size: 0.9rem;
  }

  .price_comm {
    font-size: 0.8rem;
  }

  .price_comm span {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 420px) {
  .card_products_comm {
    grid-template-columns: auto auto;
    margin: 0px 4px;
  }
  .single__product_comm {
    margin: 5px;
    max-width: 180px;
  }
  .product__content_comm h6 {
    font-size: 0.8rem;
  }
  .price_comm {
    font-size: 0.7rem;
  }
  .price_comm span {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 390px) {
  .card_products {
    grid-template-columns: auto auto;
    margin: 0px 5px;
  }
  .single__product {
    margin: 5px;
    max-width: 150px;
  }
  .product__content h6 {
    font-size: 0.8rem;
  }
  .price {
    font-size: 0.7rem;
  }
  .price span {
    font-size: 0.8rem;
  }
}
